<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
    >
      Add Tag
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      centered
      title="Add Tag"
      ok-variant="danger"
      ok-title="ยกเลิก"
    >
      <b-card-text>
        <b-form novalidate class="needs-validation" @submit.prevent>
          <b-form-row>
            <b-col>
              <label for="input-invalid2">ชื่อแท็ก</label>
              <b-form-input
                id="input-invalid2"
                v-model="tag"
                :state="tag.length > 0"
                placeholder="ใส่ชื่อแท็ก"
              />
            </b-col>

            <!-- submit button -->
            <b-col cols="12">
              <br />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="save()"
              >
                บันทึก
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  VBModal,
  BAlert,
  BFormRow,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    VBModal,
    BAlert,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
      tag: "",
      store_id: localStorage.getItem("store_id"),
    };
  },
  watch: {},
  mounted() {},
  created() {},
  computed: {},
  methods: {
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      if (this.tag) {
        this.$swal({
          title: "คุณต้องการเพิ่ม TAG?",
          text: this.name_beach,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึก!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let formData = new FormData();
            formData.append("store_id", this.store_id);
            formData.append("tag", this.tag);
            const params = {
              store_id: this.store_id,
              tag: this.tag,
            };
            console.log("params :loaddata_store_id>> ", params);

            api
              .post("tagadd", formData, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log(response.data);
                this.dialog1 = false;
                this.refresh();
                this.tag = "";
                this.$swal({
                  icon: "success",
                  title: "เพิ่ม TAG สำเร็จ ",
                  timer: 1500,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              });
          }
        });
      } else {
        if (!this.tag) {
          this.check.tag = true;
          console.log("tag null");
        }

        this.$swal({
          title: "ตรวจสอบข้อมูลใหม่อีกครั้ง",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>
