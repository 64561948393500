<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      title="Detail"
      ok-only
      ok-title="Exit"
      no-stacking
    >
      <b-card-text>
          <b-form novalidate class="needs-validation" @submit.prevent>
          <b-form-row>
            <b-col>
              <label for="input-invalid2">ชื่อแท็ก</label>
              <b-form-input
                id="input-invalid2"
                v-model="tag"
                readonly
              />
            </b-col>

           
          </b-form-row>
        </b-form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
 BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  VBModal,
  BAlert,
  BFormRow,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";

export default {
  components: {
    BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  VBModal,
  BAlert,
  BFormRow,
  BFormValidFeedback,
  BFormInvalidFeedback,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      dialog: null,
       tag: "",
    };
  },
  methods: {
    async showModaldata(id) {
      console.log("id", id);
      const params = {
        id: id,
      };
      api.post("/tagdeail", params).then((response) => {
        console.log(response.data);

        for (let item of response.data.data) {
           this.tag = item.name
        }
      });
      this.dialog = true;
    },
  },
};
</script>

