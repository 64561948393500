<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialog"
      size="lg"
      title="Detail"
      ok-only
      ok-title="Exit"
      no-stacking
    >
      <b-card-text>
        <b-form novalidate class="needs-validation" @submit.prevent>
          <b-form-row>
            <b-col>
              <label for="input-invalid2">ชื่อแท็ก</label>
              <b-form-input
                id="input-invalid2"
                v-model="tag"
                :state="tag.length > 0"
                placeholder="ใส่ชื่อแท็ก"
              />
            </b-col>

            <!-- submit button -->
            <b-col cols="12">
              <br />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="save()"
              >
                บันทึก
              </b-button>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  VBModal,
  BAlert,
  BFormRow,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    VBModal,
    BAlert,
    BFormRow,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      dialog: null,
      tag: "",
      store_id: localStorage.getItem("store_id"),
    };
  },
  methods: {
    async showModaledit(id) {
      this.id = id;
      console.log("id", id);
      const params = {
        id: id,
      };
      api.post("/tageidt", params).then((response) => {
        console.log(response.data);

        for (let item of response.data.data) {
          this.tag = item.name;
        }
      });
      this.dialog = true;
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      if (this.tag) {
        this.$swal({
          title: "คุณต้องการเพิ่ม TAG?",
          text: this.name_beach,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "บันทึก!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            let formData = new FormData();
            formData.append("id", this.id);
            formData.append("tag", this.tag);
              formData.append("store_id", this.store_id);
            api
              .post("tagupdate", formData, {
                headers: {
                  Authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log(response.data);
                this.refresh();
                this.tag = "";
                this.dialog = false;
                this.$swal({
                  icon: "success",
                  title: "อัพเดพ TAG สำเร็จ ",
                  timer: 1500,
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              });
          }
        });
      } else {
        if (!this.tag) {
          this.check.tag = true;
          console.log("tag null");
        }

        this.$swal({
          title: "ตรวจสอบข้อมูลใหม่อีกครั้ง",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
  },
};
</script>

